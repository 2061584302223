/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Andrisam
==============================================================================================*/
@import "sass-lib";


@media all and(max-width: 767px) { 
	
	.wrapper, .container {width: auto; margin: 0 15px;}  
	.btn-download{
		margin-bottom: 30px; font-size: 14px; line-height: 40px; padding: 0 30px 0 50px;
	}
	.btn {
		font-size: 13px; 
		&.more {
			&.white {font-size: 13px;} 
		}
	}

	.styledSelect {
		select {font-size: 15px;} 
	}

	.comingsoon {
		.container {width: auto; padding: 0 25px;}   
		.cm-mid {
			padding: 100px 0 150px 0; 
			.title {font-size: 20px; line-height: 34px;}  
			p {padding: 0 0; font-size: 14px; line-height: 20px;}       
		}
		.cm-bot { 
			font-size: 13px;
			p {line-height: 18px;}    
		}
	}
	
	.side-nav {
		/*width: 91.94%; left: -91.94%; overflow: visible;*/
		.fl {
			height: 100%; padding: 0 90px 0 22px;   				
			.as-top {margin: 0 0 30px 0;}			
			.inn {padding: 25px 22px 25px 22px;}  
			.as-nav {
				padding: 0 0 40px 0;
				ul {
					width: 100%; 
					li {
						a {font-size: 18px;} 
						ul {
							position: relative; left: 0; top: 0; left: 0; margin: 0 0 15px 0;  
							padding: 0 0 0 10px;   
							&:before { 
								display:none; 
							}
							li {
								a {
									font-size: 14px; font-style: italic; padding: 8px 0;
									font-weight: normal; 
								}  
							}
						}
					}
				}
			}			
			.box-lang-mob { 
		        float: left; display: block;
		        .selectLang {
		            position: relative; display: inline-block; padding: 0 0 0 0;  
		            select {
		                height: 40px; padding: 0 15px 0 0; border:0; cursor: pointer; color: $c_333; font-weight: bold;
		                background: none; position: relative; z-index: 4; 
		                @include boxSizing(border-box);
		                option {color: $c_333; padding: 3px;}  
		            }
		            &:after { 
		                position:absolute; width: 10px; height: 100%; content: ""; top: 0; right: 0; 
		                background: url('../images/material/arr-v-2.png') no-repeat center center;  
		            }
		        }
		    } 
		} 
		.fr {width: 46px; right: -46px; height: 40px; @include transition(all .4s linear);}    
		.burger-nav {margin: 19px 11px 0 0px; position: absolute; right: 0;}    
		.txt-scroll {
			display: none;
			&:before {height:0; width: 0;} 		
		} 
		 &.expanded {			
			height: 100%;			
			.fr {
				height: 100%; background:$c_blue; width: 46px;
				.burger-nav {right: 0; margin: 25px 10px 0 11px;} 
			}			
		} 		
	}
	header {
		background:$c_blue; height: 60px; padding: 8px 0 0 0;  
		.logo {
			position: absolute; left: 50%; width: 40px; height: auto; 
			@include transform(translateX(-50%)); 
			a {
				display: block; width: 40px; height: auto; 
				/*background:url('../images/logo-mob.png') no-repeat center center;   */
				background-size: auto 100% !important;  
				img {
					&.logo-1 {display: none;}
               		&.logo-2 {display: none;}
               		&.logo-mob {display: block;}
				}
			}
		}
		.fr {position: static;}
		.box-lang {display: none;}  
		.box-search {
			display: none; 
			position: absolute; width: 100%; top: 58px; left: 0; margin: 0;
			@include placeholder {color: $c_333;}
			input {
				background:$c_fff; border:1px solid $c_ccc; 
				@include boxShadow(0); 				
				&[type="text"] {width: 100%; color: $c_333;}  
				&[type="submit"] {
					position: absolute; top: 0; right: 0; border:none;
				}
			}			
		} 
		.box-search-mob {
			display: block; width: 20px; height: 20px; margin: 12px 0 0 0; position: relative;  
			.ico {
				position: absolute; width: 20px; height: 20px; cursor: pointer; top: 0; right: 0; 
				background:url('../images/material/ico-search-med.png') no-repeat center center;  				
				&.exp {
					background:url('../images/material/pop_close-2.png') no-repeat 0 0;
					background-size: 100% 100% !important;  
				}			
			}			
		}

		&.warna { 
			.logo { 
				a {					
					background:url('../images/logo-mob.png') no-repeat center center;  
					img {
						&.logo-1 {display: none;}
	               		&.logo-2 {display: none;}
					}
				}
			}
			.box-search {
	            input {
	                &[type="submit"] {
	                    background: url('../images/material/ico-search.png') no-repeat center center; 
	                }
	            }
	        }
			.bgwhite {display: none !important;} 
		}
	}
	
	.middle {
		padding: 60px 0 0 0; 
		.container {width: auto;} 
		.banner {
			height: auto;
			.caption { 
				.small {font-size: 10px; margin: 0 0 10px 0;}       
				.title {font-size: 20px; line-height: 24px; margin-bottom: 10px;}  
				.des {display: none;}   
				.btn.more.white {
					font-size: 12px; font-weight: normal; padding: 0 0 10px 0;
					&:after {height:2px;} 
				}
			}
			/* .img {
			 	height: 420px;
			 	img {height: 100%; object-fit: cover;}  
			 }  */ 
			.img {
				height: auto;
				img {height: 100%; width: 100%; object-fit: cover;}  
			} 
			.slick-initialized {
				.slick-dots {
					text-align: center; bottom: 7px;  
					li {
						width: 13px; height: 13px; margin: 0 2px;
						button {
							width: 4px; height: 4px; 
						}
						&.slick-active {
							button {width: 7px; height: 7px; text-indent: 999999px;}   
						}
					} 
				} 
			}
			&.v2 {
				.img {
					min-height: 215px; max-height: 215px; height: 215px;
					img {
						height: 100%;	
					}   
				}
				.caption {
					.title {font-size: 28px; line-height: 32px; padding: 0 20px;}
				}
			}			
		}
		
		.colbox {
			h2 {
				font-size: 12px; 
				span {
					padding: 0 0 0 40px; 
					&:before {width: 25px; left: 6px;}   
				}
				&.no {
					margin-bottom: 10px;  
					i {font-size: 12px;} 
				}
			}
			h3 {font-size: 24px; line-height: 30px;} 
			h4 {font-size: 18px;}
			.des {
				line-height: 26px;
				&.v2{
					height: 49px;
				}
			} 
			.authorized {margin-bottom: 20px;} 
			&.v2 {
				h2 {
					span {
						padding: 0 0 0 40px; 
						&:before {width:25px;}  
					}
				}
				h3 {font-size: 24px; margin-bottom: 15px;}    
			}
		}

		.home-about {
			text-align: center; 
			.container {padding: 50px 0 20px 0;}  
			.col {
				&.text {padding: 0 0 15px 0;} 
				&.img {
					margin-top: 0; right:0; 
					img {width: 100%;} 
				}
			}
		}

		.box-tab {
			.nav-tab {
				ul {
					li {
						a {font-size: 12px; padding: 0 14px;} 
					}
				}
			}
		}
		
		.box-contact {
			text-align: center; 
			.cl {
				&.text {
					padding: 45px 0 20px 0; 
					p {
						font-size: 13px; line-height: 18px;   
					}
				}
			}
			.map {height: 320px;} 
		}

		.col-corp {
			.fl {
				width: 100%; border-bottom: 1px solid $c_b5b; border-right: 0; padding: 5px 0 0 0; 
				.col {border:none; height: 130px;}  
			}  
			.fr {
				width: 100%; padding: 10px 0 25px 0; 
				.col {
					border:none; 
				}
			} 
		}

		.midcontent {
			.container {width: auto; margin: 0 20px; padding: 40px 0;}  
			&.v3,
			&.v4 {
				.container {width: auto; padding: 40px 0 40px 0;}     
			}
			.box-img {
				&.play {
					height: 200px;
					.img {
						&:after {width: 30px; height: 30px; background-size: 100% 100%;}     
					}
				}  
				&.img-slider {
					.slick-dots {bottom: 5px;} 
				}
			}
			.box-vimis {
				padding: 30px 0;
				.row {
					padding: 0 0 35px 0; margin: 0 0 35px 0;
					.col {
						&.vtitle {width: 100%;}
						&.vdes {width: 100%; padding: 0 0 0 0;}      
					}
				}
			} 
			.col-milestone {
				.row {
					height: auto;
					&:before {top:100%; height: 0px; display: none;}   
					.col {
						.txt {bottom: 0;} 
						&:nth-child(even) {
							&:before {top:0; margin-top: 0;}  
							.txt {
								top: 0; bottom: -18px;  
								.mile-ico {order:1; -webkit-order:1;} 
								.mile-des {order:2; -webkit-order:2;}
								.mile-year {
									order:3; -webkit-order:3; padding: 25px 0 0 0; 
									.start {bottom: 0;}  
								} 
							}
						}
					}
				}
			}
			.inner { 
				padding: 20px 0;   
			}
			&.career-detail {
				table {
					margin-bottom: 50px;
					tr {
						td {line-height: 20px; padding: 5px 3px;}      
					}
				}  
				ul {
					margin-bottom: 50px;  
				}
			}
		}

		.list-row {
			&.v2 {
				.container { 
					width: auto; padding: 40px 0;
					flex-flow: column;
				    display: -webkit-flex;
				    display: flex;
				    -webkit-flex-flow: column
				}  
				.row {margin: 0 0 40px 0; padding-bottom: 25px;}  
				.col {					 
					&.img {width: 100%; margin-bottom: 25px; display: block; order: 2; -webkit-order:2;} 
					&.text {
						width: 100%; display: block; order: 1; -webkit-order:1; 
						margin-bottom:0; padding: 0 0 0 0; 
					}
				} 
			}
			&.v3 {
				.col {
					&.img {width: 100%;}  
				}
			}
			&.v4{
				.container { 
					width: auto; padding: 40px 0;
					flex-flow: column;
				    display: -webkit-flex;
				    display: flex;
				    -webkit-flex-flow: column
				}  
				.row {margin: 0 0 40px 0; padding-bottom: 25px;}  
				.col {					 
					&.img {width: 100%; margin-bottom: 25px; display: block; order: 2; -webkit-order:2;} 
					&.text {
						width: 100%; display: block; order: 1; -webkit-order:1; 
						margin-bottom:0; padding: 0 0 0 0; 
					}
				} 
			}
			&.business {
				.row {
					margin-bottom: 40px; 
					.col {
						&.img {width: 100%; margin: 0 0 20px 0;} 
						&.text {width: 100%; padding: 0;} 
					}
					&:nth-child(even) {
						 .col {
						 	&.text {padding-right: 0;}  
						 }
					}
				}
			}
		}

		.list-manag {
			.col {
				width: 100%; padding: 0 0 15px 0; 
				.name {height: auto;}
				.position {margin-bottom: 15px;}  
				&:nth-child(2n+2) {padding-left: 0;}  
			}
		}

		.col-achievement {
			.col {width:96%; margin: 0 0 30px 0;} 
		}

		.filtering {  
			.styledSelect {display: block;} 
			.fl {
				width: 100%; margin-bottom: 20px;
				label {display: block; margin: 0 0 15px 0; font-size: 14px;}   
			}      
			.fr {
				width: 100%;				
			}   
		}

		.list-csr {
			.col {width:100%; margin: 0 0 20px 0;}  
		}

		.paging {
			ul {
				margin:0; text-align: center; 
			}
		}

		.our-product {			
			.row {
				padding: 30px 0 20px 0;   
				h3 {
					font-size: 19px; margin-bottom: 15px; padding-left: 30px; 
					&:before {width: 20px;} 
				}  
				.box {
					.col {
						&.gallery {
							width: 100%; margin-bottom: 20px;  
							img {width: 100%;} 							
							.slick-dots {
								margin-left: 0; position: absolute; width: 100%; left: 0; bottom: 8px; 
								text-align: center; height: 10px; 
								li {
									display: inline-block; margin: 0 4px; line-height: 5px; list-style: none;									
									button {
										width: 10px; height: 10px; padding: 0; border:0; border:2px solid $c_fff;
										background: none; text-indent: -9999999px; 
										@include boxSizing(border-box); @include borderRadius(50%);
										@include boxShadow(1px 1px 1px #333); 
									}
									&.slick-active {
										button {background:$c_fff;} 
									}
								}
							}
						} 
						&.text {width: 100%; padding: 0; min-height: 100%; max-height: 100%;}      
					}
					.thumbSlider { 
						width: 100%; display: none; 
						/* .thumb {
								width: 10px; height: 10px;  
								img {width: 0; height: 0;} 
							} */	
					}
				}
				.galSlider {padding-top: 30px;}  
				.slick-arrow {
					width: 38px; height: 28px; position: absolute; top: 50%; text-indent: -999999px; 
					padding: 15px;
					border:0; z-index: 45; 
					@include transform(scale(0.8));   
					&.slick-prev {
						left: -4px; 
						background:rgba(0,0,0, 0.5) url('../images/material/btn-arrow-prev.png') no-repeat center center;
					}
					&.slick-next {
						right: -4px; 
						background:rgba(0,0,0, 0.5) url('../images/material/btn-arrow-next.png') no-repeat center center;
					}
					&.slick-disabled {opacity: 0.5;}
				}
			}
		}

		.box-contact {
			&.page-contact {
				h3 {font-size: 24px; text-align: left; line-height: 28px;}   
				.container {padding: 40px 0; width: auto;} 
				.cl {
					&.text {
						margin-bottom: 30px; 
						.nav-tab {
							width: 100%; position: relative;
							ul {
								text-align: center; 
								li {
									display: inline-block; list-style: none;  
									a {
										display: block; padding: 0 10px 0 10px; text-align: left;
										font-weight: bold; 
									}    
									&.active {										
										a {											
											/* &:after {
												position:absolute; width: 10px; height: 6px; content: ""; right: 10px;
												top: 50%; @include transform(translateY(-50%));  
												background:url('../images/material/ico-drop.png') no-repeat 0 0;
											} */
										} 
									}
								}
							}
						} 
						.tab-container {
							padding: 0; width: 100%;  
						}
					}
					&.form {
						label {text-align: left;}  
					}
				}
				.map {
					height: 200px;  
				}
			}
		}

		.list-career {
			padding: 40px 0 0 0; margin: 0 0 0 0;   
			.row {				
				.col {
					width: 100%; margin: 0 0 18px 0;
					.btn {
						display: block; width: 90%; left: 50%; text-align: center;  
						@include boxSizing(border-box); @include transform(translateX(-50%)); 
					}  
				}  
			}
		}

	}

	.content-404 {
		.c4-title {font-size: 65px;} 
	}

	footer {
		text-align: center; 
		.logo-foot {display: none;} 
		.fr {width: 100%;}
		.nav-foot {
			margin:0 0 15px 0;
			ul {
				li {
					a {padding: 5px 5px; font-size: 11px;}  
				}
			}
		} 
		.copyright {
			text-align: center; font-size: 9px; 
		}
	}

	header, footer, .middle {padding-left: 0;} 

	#boxPop {
		min-width: 85%; max-width: 85%;
		.close_pop {right: 0; top: -40px;}
		.content_pop {
			.sc_content {
				.inner {
					padding: 20px 20px 40px 20px;  
					.detImg {width: 100%; margin-bottom: 30px;}    
					.detTxt {
						width: 100%; padding: 0;
						.detYear {font-size: 12px; margin: 0 0 10px 0;}  
						.detTitle {
							font-size: 28px; line-height: 32px;
							margin-bottom: 0;
						} 
					}   
				}
			}
		}
		.popArrow {
			z-index: 155; padding: 5px; opacity: 0.9;
			&.prev {
				left:-20px; 
				background: rgba(0,0,0, 0.9) url("../images/material/btn-arrow-prev.png") no-repeat center center;
			}
			&.next {
				right:-20px; 
				background: rgba(0,0,0, 0.9) url("../images/material/btn-arrow-next.png") no-repeat center center;
			} 
		}
	}

	.g-recaptcha {
		transform: scale(1) !important; 
		webkit-transform: scale(1) !important; 
		> div {
			width: 100% !important; 
			> div {  
			    width: 100% !important;
			    height: 78px;
			    transform: scale(0.7);
			    webkit-transform: scale(0.7);
			    text-align: center;
			    position: relative;
			    margin-left: 0px;	
			}    
		  }
	  }

	
}



@media all and(max-width: 479px) { 
	.middle {
		.list-result {
			.row {
				a {
					.col {
						&.thumb {
							width: 100%; margin-bottom: 15px;
						}
						&.text {
							width: 100%; 
							.title {font-size: 16px;} 
						}
					}
				}
				&.thumb {
					a {
						.col {
							&.text {width: 100%; padding: 0;} 
						}
					}
				}
			}
		}
	}
}